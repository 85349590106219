import React from 'react';
import PropTypes from 'prop-types';
import titlize from '../../utilities/string_utilities';
import { Link, ProductCategoryThumbnail } from '../../ui_kit';

function CategoryCard({ category, category: { id, description, product_subcategories }, index }) {
  return (
    <Link className="component-category-card" href={`/products?product_category_id=${id}`}>
      <div className="category-card-cats-and-subcats">
        <h2 className="category-card-title">{titlize(description)}</h2>

        <ul className="category-card-subcategories">
          {product_subcategories &&
            product_subcategories.map((subcat) => (
              <li key={subcat.id}>
                <Link href={`/products?product_category_id=${id}&product_subcategory_id=${subcat.id}`}>{subcat.description}</Link>
              </li>
            ))}
        </ul>
      </div>

      <div className="category-card-image-container">
        <div className={`category-card-dot ${[1, 2].includes(index) ? 'pink' : 'green'}`}>
          <div className="image-container">
            <ProductCategoryThumbnail category={category} />
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CategoryCard;

CategoryCard.propTypes = {
  category: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
