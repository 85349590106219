import React from 'react';
import ReactDOM from 'react-dom';
import CategoryListings from '../src/components/landing_page/category_listings';

function HomeCategories() {
  return (
    <div id="component-home-categories">
      <h2 className="home-categories-title">Browse Product Categories</h2>

      <CategoryListings />
    </div>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('home-categories-mount-point');

  if (element) {
    ReactDOM.render(<HomeCategories />, element);
  }
});
