import React, { useState, useEffect } from 'react';
import CategoryCard from './category_card';

function CategoryListings() {
  const [categories, setCategories] = useState([]);

  function setupCategories() {
    $.getJSON('/product_categories', { homepage: true }, (result) => setCategories(result));
  }

  useEffect(() => {
    setupCategories();
  }, []);

  return (
    <div className="component-category-listings">
      {categories.map((category, index) => (
        <CategoryCard key={index} category={category} index={index} />
      ))}
    </div>
  );
}

export default CategoryListings;
